import "./style.scss";

import React, { useEffect, useState } from "react";
import { Typography, Box, Button, CircularProgress, Skeleton, Dialog, DialogContent, DialogActions, IconButton } from "@mui/material";

import TopBar from "../../../components/TopBar";

import BottomBar from "../../../components/BottomBar";
import EmailHarvesting from "../../../components/EmailHarvesting";

import ProductDownload from "../../../components/ProductDownload";
import { getProduct } from "../../../util/api/productApi";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../actions/notificationActions";
import { AmplifierBanner, CabinetBanner, StandAloneBanner, MainBanner } from "../../../components/Products/Distant";

import AccountDialog from "../../../components/AccountDialog";
import BuyButton from "../../../components/BuyButton";

import { Helmet } from 'react-helmet';
import OverviewBanner from "../../../components/Products/Distant/Overview";
import YoutubeBanner from "../../../components/Products/Distant/YoutubeBanner";
import { PriceContent } from "../../../components/PriceComponents";
import PrePredals from "../../../components/Products/Distant/PrePedals";
import EQBanner from "../../../components/Products/Distant/EQBanner";
import PostFX from "../../../components/Products/Distant/PostFx";
import PluginUtilities from "../../../components/Products/Distant/Utilities";

import originOfMadness from "../../../static/images/distant_amp/origin_of_madness.jpeg"
import asiaAustralia from "../../../static/images/distant_amp/asia_australia.jpeg"
import CloseIcon from '@mui/icons-material/Close';

import ReactPixel from 'react-facebook-pixel';
import { useSearchParams } from "react-router-dom";

const PRODUCT_ID = "distant-amplifier-suite"

const ExtraContentRightTopBar = ({ trigger, onTrial, priceContent, product }) => {
  return <Box
    className={trigger ? "reveal active" : "reveal"}
    sx={{
      p: 2,
      display: "flex",
      flexDirection: "row",
      columnGap: 2,
      color: "#fff",
      alignItems: "center"
    }}>
    {priceContent}
    <Button
      variant="outlined"
      size="small"
      onClick={onTrial}
    >
      Free Trial
    </Button>
    <BuyButton size="small" product={product} sx={{ width: "100px" }} />
  </Box>
}

const ExtraContentMobile = ({ product, priceContent }) => {
  return <Box sx={{
    p: 2,
    display: "flex",
    flexDirection: "row",
    columnGap: 2,
    color: "#fff",
    alignItems: "center"
  }}>
    {priceContent}
    <BuyButton size="small" product={product} sx={{ width: "90px" }} />
  </Box>
}

const PromoValues = {
  "MADNESS20": <><b>20% discount</b></>,
  "CodeDistant30Tour": <>exclusive liveshow <b>30% discount</b></>,
  "Code50DistantAsia": <>exclusive liveshow <b>50% discount</b></>
}

const Tourname = {
  "MADNESS20": "The Origin of Madness Tour",
  "CodeDistant30Tour": "The Asia & Australia 2025 Tour",
  "Code50DistantAsia": "The Asia & Australia 2025 Tour"
}

const Images = {
  "MADNESS20": originOfMadness,
  "CodeDistant30Tour": asiaAustralia,
  "Code50DistantAsia": asiaAustralia
}


const PromoPopup = ({ open, onClose, product, code }) => {
  return <Dialog open={open} onClose={onClose}
    PaperProps={{
      elevation: 10,
    }}
  >
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={(theme) => ({
        position: 'absolute',
        right: 8,
        top: 8,
        color: "white",
      })}
    > <CloseIcon /></IconButton>
    <img src={Images[code]} />

    <DialogContent sx={{ justifyContent: "center", textAlign: "center" }}>
      <Box>
        <i>{Tourname[code]}</i> is here! <br />Claim your {PromoValues[code]} now at checkout!
      </Box>

      <Box sx={{ mt: 3 }}>
        <BuyButton text={"Claim Offer"} product={product}></BuyButton>
      </Box>
    </DialogContent>
  </Dialog >
}

const ReclameBanner = ({ code }) => {
  return (
    <Box sx={{
      bottom: 0,
      width: "100%",
      position: 'fixed',
      alignItems: "center",
      textAlign: "center",
      padding: 2,
      background: "black",
      zIndex: 10,
    }}>
      <Typography variant="h5" sx={{ fontSize: { xs: 16, md: 28 } }}><i>{Tourname[code]}</i> is here! Claim your {PromoValues[code]} now at checkout!</Typography>
    </Box>
  )
}

const DistantAmplifierSuitePage = ({ useHelmet = true }) => {

  const [searchParams, setSearchParams] = useSearchParams();


  const [downloadOpen, setDownloadOpen] = useState(false);
  const [product, setProduct] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [price, setPrice] = useState(undefined)
  const [trigger, setTrigger] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [promoOpen, setPromoOpen] = useState(false)
  const [promoCode, setPromoCode] = useState(null)
  const [showPromoBanner, setShowPromoBanner] = useState(false);

  window.onscroll = function () {
    var scrollLimit = 500;
    setTrigger(window.scrollY >= scrollLimit);
  };

  const dispatch = useDispatch();
  const state = useSelector((state) => state)

  const fetchProduct = () => {
    getProduct(PRODUCT_ID).then((res) => {
      setLoading(false)
      setProduct(res?.data)

    }).catch(() => {
      setLoading(false)
      dispatch(setError("Something went wrong, please contact support."))
    })
  }

  useEffect(() => {
    fetchProduct()
    // eslint-disable-next-line
  }, [state?.token])

  useEffect(() => {
    const promocode = searchParams.get("promo");
    setPromoCode(promocode);
    // eslint-disable-next-line
  }, [searchParams])

  useEffect(() => {
    setTimeout(() => { setPromoOpen(promoCode !== null) }, 666)

  }, [promoCode])

  useEffect(() => {
    if (product) {
      setPrice(`€${(product?.default_price?.unit_amount / 100).toFixed(2)}`)
    }
  }, [product])

  const handleTrial = () => {
    setDownloadOpen(true)
  }




  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      {useHelmet && <Helmet>
        <title>WaveMind Audio | Tone Suite Distant</title>
        <link rel="canonical" href="https://wavemind.net/product/distant-amplifier-suite" />
      </Helmet>}
      <TopBar
        hideTrigger={trigger}
        rightExtraAction={<ExtraContentRightTopBar trigger={trigger} product={product} onTrial={handleTrial} priceContent={<PriceContent name="ToneSuite - Distant" product={product} hideVAT />} />}
        extraContentMobile={trigger ? <ExtraContentMobile product={product} priceContent={<PriceContent name="ToneSuite - Distant" product={product} hideVAT />} /> : undefined} />
      {/* <CouponAlert /> */}
      <PromoPopup code={promoCode} open={promoOpen} product={product} onClose={() => { setPromoOpen(false); setShowPromoBanner(true) }} />
      <ProductDownload requireEmail open={downloadOpen} onClose={() => setDownloadOpen(false)} productID={PRODUCT_ID} />
      <AccountDialog open={accountDialogOpen} onClose={() => { setAccountDialogOpen(false) }} />

      <MainBanner price={price} product={product} onTrial={handleTrial} />
      {showPromoBanner && <ReclameBanner code={promoCode} />}
      <OverviewBanner />
      <PrePredals />
      <AmplifierBanner />
      <CabinetBanner />
      <EQBanner />
      <PostFX />
      <PluginUtilities />
      <YoutubeBanner />
      <StandAloneBanner />
      <EmailHarvesting />
      <BottomBar />
    </div>
  );
};

export default DistantAmplifierSuitePage;
