import { Box, Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom"
import TopBar from "../../components/TopBar";
import BottomBar from "../../components/BottomBar";
import { postDownload } from "../../util/api/downloadApi";
import { useDispatch } from "react-redux";
import { setError } from "../../actions/notificationActions";
import { useEffect } from "react";

import { PRODUCT_NAMES, PRODUCT_IMAGES } from "../../components/ProductDetails";
import { LoggedOut } from "../../components/AccessControl";


const DownloadProviderPage = () => {
  const { os, productID } = useParams();


  const handleStart = () => {
    const url = `/downloadmanager/${productID}/${os}`
    const handle = window.open(url, "_blank");
    handle?.blur()
    window.focus();
    // postDownload(productID, os).then((response) => {
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', response.headers?.["x-file-name"]); //or any other extension
    //   document.body.appendChild(link);
    //   link.click();
    // }).catch((e) => {
    //   dispatch(setError(e?.response?.data?.detail))
    // })
  }

  const handleExtraDownload = (extraID) => {
    const url = `/downloads/${os}/${extraID}`
    const handle = window.open(url, "_blank");
    handle?.blur()
    window.focus();
  }

  const ProductSuggestion = () => {
    let productsToSuggest = ["rev-f-amplifier-suite", "distant-amplifier-suite"]

    const index = productsToSuggest.indexOf(productID);
    if (index > -1) { // only splice array when item is found
      productsToSuggest.splice(index, 1); // 2nd parameter means remove one item only
    }

    let randomElement = productsToSuggest[Math.floor(Math.random() * productsToSuggest.length)];

    return <div>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
        <img style={{ width: "500px" }} src={PRODUCT_IMAGES[randomElement]} />
      </Box>
      <Typography variant="h4">While you're waiting, have you tried {PRODUCT_NAMES[randomElement]} yet?</Typography>

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", gap: 4 }}>
        <Button onClick={() => window.open(`/product/${randomElement}`, "_blank")} sx={{ mt: 2 }} variant="contained">More Info</Button>
        <Button onClick={() => handleExtraDownload(randomElement)} sx={{ mt: 2 }} variant="contained">Try it now for free</Button>
      </Box>
    </div>
  }

  return <div
    style={{ minHeight: "100vh", display: "flex", flexDirection: "column", background: "radial-gradient(circle, rgb(204, 204, 214) 0%, rgb(86, 86, 105) 100%)" }}
  >
    <TopBar />
    <Box
      sx={{
        backgroundSize: "cover",
        position: "relative",
        flex: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        color: "black",
        minHeight: "100vh"
      }}
    >
      <Typography variant="h3">Your download for {PRODUCT_NAMES[productID]} will start soon, enjoy!</Typography>
      <Button onClick={handleStart} sx={{ color: "black", mb: 10 }}>Click here if it does not start automatically</Button>


      <ProductSuggestion />


      <iframe style={{ display: "none" }} src={`/downloadmanager/${productID}/${os}`} />
    </Box>

    <BottomBar />

  </div>
}

export default DownloadProviderPage
